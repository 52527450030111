import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function AreYouSureDialog({ show, onHide, size, modalData }) {

    if (!size) {
        size = "md";
    }

    const handleClose = (event) => {
        event.preventDefault();
        onHide();
    }

    const handleSave = (event) => {
        event.preventDefault();
        modalData['action']();
        onHide();
    }

    return (

        <Modal
            id="are_you_sure_modal"
            show={ show }
            onHide={ onHide }
            size={ size }
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Are you sure?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { modalData['message'] }
            </Modal.Body>
            <Modal.Footer>
                <Button id="are_you_sure_no" variant="secondary" onClick={handleClose}>
                    { modalData['reject'] }
                </Button>
                <Button id="are_you_sure_yes" variant="primary" onClick={handleSave}>
                    { modalData['confirm'] }
                </Button>
            </Modal.Footer>
        </Modal>

    );
}

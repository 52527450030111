import { useState } from 'react';

export default function useMode() {

    const getMode = () => {
        try {
            const modeString = localStorage.getItem('mode');
            if (modeString === null) {
                localStorage.setItem('mode', 'Default');
                return 'Default';
            }
            else {
                return modeString;
            }
        } catch (e) {
            console.log('error loading mode: ' + e);
            return 'Default';
        }
    };

    const [mode, setMode4Hook] = useState(getMode());

    const setMode = (modeNew) => {
        localStorage.setItem('mode', modeNew);
        setMode4Hook(modeNew);
        console.log('loaded mode: ' + modeNew);
    };

    return {
        mode
    ,   setMode
    }
}
import React from 'react';

export default function ModeSelect({ element_id, modes, mode, setMode }) {

    return (
        <>
        <select className="form-select" id={ element_id } value={ mode } onChange={(e) => setMode(e.target.value)} >
            {modes.map((t) => (
                <option value={ t } key={ element_id + '_' + t }>{t}</option>
            ))}
        </select>
        </>
    );
}
import React, { useCallback, useEffect, useState } from 'react';

import Alert from 'react-bootstrap/Alert';

export default function DefaultMode({ handleAPIRequest, setMode }) {

    const [alertError, setAlertError] = useState(null)

    const fetchDefaultMode = useCallback( async () => {
        
        const modeString = localStorage.getItem('mode');
        
        if (modeString === 'Default') {
            const res = await handleAPIRequest('/api/v1/default_mode');
            const data = await res.json();
    
            if (res.status === 200) {
                setMode(data['default_mode']);
                console.log('loaded default mode: ' + data['default_mode']);
            } 
            else {
                setMode('Cost');
                console.log(JSON.stringify(data));
                setAlertError({
                    title: data.name
                ,   body: data.description.replace(/\n/g, "<br />")
                });
            }
        }
    }, [handleAPIRequest, setAlertError, setMode]);

    useEffect(() => {
        fetchDefaultMode();
    }, [fetchDefaultMode, handleAPIRequest]); 

    return (
        <>
        <Alert show={alertError} variant="danger">
            <Alert.Heading>{alertError ? alertError.title : ''}</Alert.Heading>
            <p dangerouslySetInnerHTML={alertError ? { __html: alertError.body } : { __html: '' }}></p>
        </Alert>
        </>
    )
}

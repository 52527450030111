import React, { useState } from 'react';
import { PlusCircle, XCircle } from 'react-bootstrap-icons'

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';

import SimulationServiceTypeSelect from '../Simulation/SimulationServiceTypeSelect';
import CountrySelect from '../../dialogs/CountrySelect';

export default function InternationalQuote({ handleAPIRequest }) {
    
    const [internationalQuoteInput, setInternationalQuoteInput] = useState({
        serviceType: null,
        serviceTypeCategory: null,
        adultPediatric: 'A'
    });

    const [selectedCountries, setSelectedCountries] = useState({
        countries: []
    });
    const [alertError, setAlertError] = useState(null);

    const setServiceTypeCategory = (serviceTypeCategory) => {
        setInternationalQuoteInput((prev) => ({
            ...prev,
            serviceTypeCategory,
            serviceType: null,
        }));
    };

    const setServiceType = (serviceType) => {
        setInternationalQuoteInput((prev) => ({
            ...prev,
            serviceType,
        }));
    };

    const onSelectCountries = (countries) => {
        const newCountries = [...selectedCountries.countries];
        countries.forEach((country) => {
            newCountries.push(country);
        });

        setSelectedCountries({
            countries: newCountries
        });
    };
    
    const onCountryDelete = (country) => {

        const newCountries = selectedCountries.countries.filter((c) => {
            return c.international_quote_id !== country.international_quote_id;
        });

        setSelectedCountries({
            countries: newCountries
        });
    };

    // ************************************************************************
    // Modals CountrySelect

    const [modal_CountrySelect_dialog_open, setCountrySelectDialogOpen] = useState(false)

    const onModalCountrySelectDialogOpen = () => {
        setCountrySelectDialogOpen(true);
    }

    return (        
        <>
        <Alert show={alertError} variant="danger">
            <Alert.Heading>{alertError ? alertError.title : ''}</Alert.Heading>
            <p dangerouslySetInnerHTML={alertError ? { __html: alertError.body } : { __html: '' }}></p>
        </Alert>
        
        <CountrySelect
            show={ modal_CountrySelect_dialog_open }
            onHide={ () => setCountrySelectDialogOpen(false) }
                size="xl"
            handleAPIRequest={ handleAPIRequest }
            selected_countries={ selectedCountries.countries }
            onSelectCountries={ onSelectCountries }
        />
        
        <div className="bg-light mt-4 mb-4 p-3">
            <Row>
                <h1>International Quote</h1>
            </Row>
            <Row className="mt-3">
                <p>HealthRate has established relationships with several prestigious medical centers worldwide and may be able to secure <strong>fixed-price bids</strong> for your case(s). By choosing this option, you could save between <strong>40-60% compared to U.S.-based medical centers</strong>.</p>
                <p>Please complete the form below, and one of our experts will promptly contact you to explain the process and gather additional details about your case(s).</p>
            </Row>
        </div>

        <div className='bg-light mt-4 mb-4 p-3'>
            <Row>
                <h2>Service Type</h2>
            </Row>

            <Row>
                <SimulationServiceTypeSelect
                    element_id="serviceType"
                    handleAPIRequest={ handleAPIRequest }
                    simulationInput={ internationalQuoteInput }
                    setServiceTypeCategory={ setServiceTypeCategory }
                    setServiceType={ setServiceType }
                />
            </Row>
        </div>

        <div className='bg-light mt-4 mb-4 p-3'>
            <Row>
                <h2>Countries</h2>
            </Row>
            
            {selectedCountries.countries.map((c) => (
                <Row 
                    className="m-3"
                    key={ `international_quote_${c.international_quote_id}` }
                >
                    <h5><Button id={`country_remove_${c.international_quote_id}`} onClick={() => onCountryDelete(c)} variant="outline-danger" ><XCircle /></Button> { c['country'] }</h5>
                </Row>
            ))}

            <div className="row border-top pt-4">
                <div className="col-md-6 mb-sm-2 mb-2">
                    <Button id="add_country" onClick={ onModalCountrySelectDialogOpen } >
                        <PlusCircle /> Add Countries
                    </Button>
                </div>
            </div>
        </div>

        <div className='bg-light mt-4 mb-4 p-3'>
            <div className="row">
                <div className="col-md-12">
                    <Button id="international_quote_submit"
                        onClick={() => {                            
                            const countriesString = selectedCountries.countries.map(country => country.country).join(', ');
                            
                            const emailBody = `Service Type Category: ${ 
                                internationalQuoteInput.serviceTypeCategory?.service_type_category || 'None'
                            }\nService Type: ${
                                internationalQuoteInput.serviceType?.service_type || 'None'
                            }\nCountries: ${countriesString || 'None'}`;
                            window.open(
                                `mailto:international@healthrate.io?subject=International Quote&body=${encodeURIComponent(
                                    emailBody
                                )}`
                            );
                        }}
                        className="btn btn-primary"
                        style={{ width: '100px' }}
                        disabled={
                            !internationalQuoteInput.serviceTypeCategory ||
                            !internationalQuoteInput.serviceType ||
                            selectedCountries.countries.length === 0  
                        }
                    >
                        Submit
                    </Button>
                </div>
            </div>
        </div>
            <style>
                {`
                    .adult-pediatric-buttons,
                    label[for^="adult_pediatric_radio_"] {
                        visibility: hidden !important;
                        pointer-events: none !important;
                    }
                `}
            </style>
    </>
    );
}

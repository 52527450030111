import React, { useCallback, useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function NetworkSearch({ element_id, handleAPIRequest, service_type_id, provider, onProviderUpdate, remainingNetworkCount }) {

    const [alertError, setAlertError] = useState(null);

    const [selectAllNetworks, setSelectAllNetworks] = useState(false);
    const [networksTempSelect, setNetworksTempSelect] = useState([]);
    const [networksTempSelectCount, setNetworksTempSelectCount] = useState(0);

    const [networksTextFilter, setNetworksTextFilter] = useState('');
    const [networksFilter] = useState({
        service_type_id,
        provider
    });
    const [networks, setNetworks] = useState({
        networks: []
    });

    const fetchNetworks = useCallback(() => {
        const do_fetch = async () => {
            const fff = {
                service_type_id,
                provider_id: provider['provider_id']
            };

            let fetch_url = '/api/v1/networks?' + new URLSearchParams(fff);

            console.log('fetching networks: ' + fetch_url);

            const res = await handleAPIRequest(fetch_url);
            const data = await res.json();

            if (res.status === 200) {
                if (Array.isArray(data['networks'])) {
                    setNetworks({
                        ...fff,
                        networks: data['networks'].map((n) => {
                            n['facility_pct_charge'] = n['facility_pct_charge'] * 100;
                            n['biologic_rate_pct_wac'] = n['biologic_rate_pct_wac'] === 999999 ? 100 : n['biologic_rate_pct_wac'] * 100;
                            n['outlier_pct_charge'] = n['outlier_pct_charge'] * 100;
                            n['mpp_pct_charge'] = n['mpp_pct_charge'] * 100;
                            return n;
                        })
                    });
                    console.log('loaded networks for ' + JSON.stringify(fff) + ' : ' + data['networks'].length);
                } else {
                    setNetworks({
                        ...fff,
                        networks: []
                    });
                    console.log(`WARNING no loaded networks for ${JSON.stringify(fff)}`);
                }
            } else {
                console.log(JSON.stringify(data));
                setAlertError({
                    title: data.name,
                    body: data.description.replace(/\n/g, "<br />")
                });
            }
        };

        if (!('service_type_id' in networks) || networksFilter['service_type_id'] !== networks['service_type_id'] || !('provider_id' in networks) || networksFilter.provider['provider_id'] !== networks['provider_id']) {
            do_fetch();
        }

    }, [handleAPIRequest, networksFilter]);

    useEffect(() => {
        fetchNetworks();
    }, [fetchNetworks, handleAPIRequest, networksFilter]);

    const onSelectNetwork = (networks) => {
        console.log('network selected: ' + JSON.stringify(networks));

        networks.forEach((n) => {
            provider['networks'].push(n);
        });
        onProviderUpdate(provider);
    };

    const isNetworkSelected = (network) => {
        return networksTempSelect.some(selectedNetwork => selectedNetwork.network_id === network.network_id);
    };

    const setAlertNetworkCount = (newNetworkTempSelectCount) => {
        if (remainingNetworkCount - newNetworkTempSelectCount <= 0) {
            setAlertError({
                title: "Network Limit Reached",
                body: `Maxium number of Networks per Simulation reached. Please remove a Network before adding another.`
            });
        } else {
            setAlertError(null);
        }
    }
    
    const handleOnSelectNetwork = (checked, network) => {
        if (checked && !isNetworkSelected(network)) {
            setNetworksTempSelect([...networksTempSelect, network]);
            setNetworksTempSelectCount(networksTempSelectCount + 1);
            setAlertNetworkCount(networksTempSelectCount + 1);
        } 
        else {
            setNetworksTempSelect(networksTempSelect.filter((n) => n['network_id'] !== network['network_id']));
            setNetworksTempSelectCount(networksTempSelectCount - 1);
            setAlertNetworkCount(networksTempSelectCount - 1);
            setSelectAllNetworks(false);
        }
    };

    const handleSelectAllNetworks = (checked) => {
        const allNetworks = networks['networks'].filter((n) => {
            return !provider['networks'].some((p) => p['network_id'] === n['network_id']);
        });

        if (checked) {
            const networksToAdd = allNetworks.slice(0, remainingNetworkCount);
            setNetworksTempSelect([...networksToAdd]);
            setNetworksTempSelectCount(networksToAdd.length);
            setAlertNetworkCount(networksToAdd.length);
        } 
        else {
            setNetworksTempSelect([]);
            setNetworksTempSelectCount(0);
            setAlertNetworkCount(0);
        }

        setSelectAllNetworks(checked);
    };

    return (
        <>
            <Alert show={alertError} variant="danger">
                <Alert.Heading>{alertError ? alertError.title : ''}</Alert.Heading>
                <p dangerouslySetInnerHTML={alertError ? { __html: alertError.body } : { __html: '' }}></p>
            </Alert>
            { networks['networks'].length === 0 ? (
                <Row className='mt-3 mb-3'><Col><h4>No Networks Available</h4></Col></Row>
            ) : (
                <>
                <Row>
                    <Col>
                        <div className="input-group">
                            <span className="input-group-text">Search</span>
                            <input type="text" className="form-control" value={ networksTextFilter } onChange={ (e) => setNetworksTextFilter(e.target.value) } id={ element_id + '_textFilter' } />
                        </div>
                    </Col>
                </Row>
                <Row className='mt-3 mb-3 pb-2 align-items-center border-bottom'>
                    <Col xs={2}>
                        <Button
                            variant={selectAllNetworks ? "primary" : "outline-primary"}
                            onClick={() => {
                                const checkbox = document.getElementById(element_id + '_all_networks_checkbox');
                                checkbox.checked = !checkbox.checked;
                                handleSelectAllNetworks(checkbox.checked);
                            }}
                            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                        >
                            <input
                                id={element_id + '_all_networks_checkbox'}
                                type="checkbox"
                                checked={selectAllNetworks}
                                onChange={(e) => handleSelectAllNetworks(e.target.checked)}
                                onClick={(e) => e.stopPropagation()}
                                style={{ marginRight: '0.5em' }}
                            />
                            <span id={element_id + '_all_networks_add'}>Add All</span>
                        </Button>
                    </Col>
                    <Col><strong>Name</strong></Col>
                </Row>
                <div id="network_list" className="scrollable-menu border-bottom">
                {networks['networks'].filter((n) => {
                    return !provider['networks'].some((p) => p['network_id'] === n['network_id']);
                })
                .map((n) => {

                    n['search_found'] = 0;

                    if (networksTextFilter === '') {
                        n['search_found'] = 1;
                        return n;
                    }

                    let filters = networksTextFilter.split(' ');

                    for (let i = 0; i < filters.length; i++) {
                        if (filters[i] === '')
                            continue;

                        let re = new RegExp(filters[i].replace(/[\!\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g, '\\$&'), 'i');
                        const result = n['network'].match(re);
                        if (result) 
                            n['search_found'] += 1;
                    }

                    return n;

                })
                .filter((n) => n['search_found'] > 0)
                .sort((a, b) => {
                    if (a['search_found'] !== b['search_found']) {
                        return a['search_found'] > b['search_found'] ? -1 : 1;
                    }
                    return a['network'] > b['network'] ? 1 : -1;
                })
                .map((n) => (
                    <Row key={ element_id + '_' + n['network_id'] } className='pt-3 pb-3 border-bottom align-items-center'>
                        <Col xs={2}>
                            <Button
                                variant={isNetworkSelected(n) ? "primary" : "outline-primary"}
                                onClick={() => {
                                    const checkbox = document.getElementById(element_id + '_' + n['network_id'] + '_checkbox');
                                    checkbox.checked = !checkbox.checked;
                                    handleOnSelectNetwork(checkbox.checked, n);
                                }}
                                disabled={ ( remainingNetworkCount - networksTempSelectCount <= 0 && isNetworkSelected(n) === false )}
                                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                            >
                                <input
                                    id={element_id + '_' + n['network_id'] + '_checkbox'}
                                    type="checkbox"
                                    checked={isNetworkSelected(n)}
                                    onChange={(e) => handleOnSelectNetwork(e.target.checked, n)}
                                    onClick={(e) => e.stopPropagation()}
                                    disabled={ ( remainingNetworkCount - networksTempSelectCount <= 0 && isNetworkSelected(n) === false )}
                                    style={{ marginRight: '0.5em' }}
                                />
                                <span id={element_id + '_' + n['network_id'] + '_add'}>Add</span>
                                
                            </Button>
                        </Col>
                        <Col>{ n['network'] }</Col>
                    </Row>
                ))}
                </div>
                <Row className="mt-3 text-end">
                    <Col><Button id="save_network" onClick={ (e) => onSelectNetwork(networksTempSelect) } disabled={networksTempSelect.length === 0}>Save</Button></Col>
                </Row>
                </>
            )}
        </>
    );
}

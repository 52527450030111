import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { PlusCircle, QuestionCircle } from 'react-bootstrap-icons'

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Tooltip from 'react-bootstrap/Tooltip';

import AreYouSureDialog from '../../dialogs/AreYouSureDialog';
import NetworkSelect from '../../dialogs/NetworkSelect';
import ProviderSelect from '../../dialogs/ProviderSelect';

import SimulationServiceTypeSelect from './SimulationServiceTypeSelect';
import SimulationSeveritySelect from './SimulationSeveritySelect';
import SimulationSeverityParams from './SimulationSeverityParams';
import SimulationProvider from './SimulationProvider';
import useSimulationInput from '../../hooks/useSimulationInput';

export default function Simulation({ handleAPIRequest, addSimulationResult, mode }) {
    
    const navigate = useNavigate();

    const [alertError, setAlertError] = useState(null)
    const [alertSuccess, setAlertSuccess] = useState(null)

    
    // ************************************************************************
    // Simulation 

    const {
        setSimulationInput
    ,   simulationInput
    ,   setUserDefinedProviderCount
    ,   userDefinedProviderCount
    ,   onSimulationReset
    ,   currentVersion
    } = useSimulationInput();

    // Max Networks

    const maxNetworks = 15;
    
    const getNetworkCount = (providers) => {
        return providers.reduce((acc, p) => acc + p.networks.length, 0);
    }
    
    const [networkCount, setNetworkCount] = useState(getNetworkCount(simulationInput.providers));

    const setAdultPediatric = (adultPediatric, serviceType) => {
        if (adultPediatric && serviceType) {
            setSimulationInput({
                adultPediatric
            ,   serviceTypeCategory: simulationInput.serviceTypeCategory
            ,   serviceType
            ,   severity: null
            ,   providers: []
            ,   applyIndex: simulationInput.applyIndex
            });
        }
        else if (adultPediatric) {
            setSimulationInput({
                adultPediatric
            ,   serviceTypeCategory: simulationInput.serviceTypeCategory
            ,   serviceType: simulationInput.serviceType
            ,   severity: null
            ,   providers: []
            ,   applyIndex: simulationInput.applyIndex
            });
        }
        else {
            setSimulationInput({
                adultPediatric: 'A'
            ,   serviceTypeCategory: simulationInput.serviceTypeCategory
            ,   serviceType: simulationInput.serviceType
            ,   severity: null
            ,   providers: []
            ,   applyIndex: simulationInput.applyIndex
            });
        }
    };

    const setServiceTypeCategory = (serviceTypeCategory) => {
        if (serviceTypeCategory) {
            setSimulationInput({
                adultPediatric: simulationInput.adultPediatric
            ,   serviceTypeCategory
            ,   serviceType: null
            ,   severity: null
            ,   providers: []
            ,   applyIndex: simulationInput.applyIndex
            });
        }
        else {
            setSimulationInput({
                adultPediatric: simulationInput.adultPediatric
            ,   serviceTypeCategory: null
            ,   serviceType: null
            ,   severity: null
            ,   providers: []
            ,   applyIndex: simulationInput.applyIndex
            });
        }
    };

    const setServiceType = (serviceType) => {
        if (serviceType) {
            setSimulationInput({
                adultPediatric: simulationInput.adultPediatric
            ,   serviceTypeCategory: simulationInput.serviceTypeCategory
            ,   serviceType 
            ,   severity: null
            ,   providers: []
            ,   applyIndex: simulationInput.applyIndex
            });
        }
        else {
            setSimulationInput({
                adultPediatric: simulationInput.adultPediatric
            ,   serviceTypeCategory: simulationInput.serviceTypeCategory
            ,   serviceType: null
            ,   severity: null
            ,   providers: []
            ,   applyIndex: simulationInput.applyIndex
            });
        }
        };

    const setSeverity = (severity) => {
        if (severity) {
            setSimulationInput({
                adultPediatric: simulationInput.adultPediatric
            ,   serviceTypeCategory: simulationInput.serviceTypeCategory
            ,   serviceType: simulationInput.serviceType
            ,   severity
            ,   providers: simulationInput.providers
            ,   applyIndex: simulationInput.applyIndex
            });
        }
        else {
            setSimulationInput({
                adultPediatric: simulationInput.adultPediatric
            ,   serviceTypeCategory: simulationInput.serviceTypeCategory
            ,   serviceType: simulationInput.serviceType
            ,   severity: null
            ,   providers: []
            ,   applyIndex: simulationInput.applyIndex
            });
        }
    };

    const setApplyIndex = (applyIndex) => {
        if (applyIndex) {
            setSimulationInput({
                adultPediatric: simulationInput.adultPediatric
            ,   serviceTypeCategory: simulationInput.serviceTypeCategory
            ,   serviceType: simulationInput.serviceType
            ,   severity: simulationInput.severity
            ,   providers: simulationInput.providers
            ,   applyIndex
            });
        }
        else {
            setSimulationInput({
                adultPediatric: simulationInput.adultPediatric
            ,   serviceTypeCategory: simulationInput.serviceTypeCategory
            ,   serviceType: simulationInput.serviceType
            ,   severity: simulationInput.severity
            ,   providers: simulationInput.providers
            ,   applyIndex: false
            });
        }
    };

    const onProvidersCreate = (providers) => {

        const newProviders = [...simulationInput.providers];
        var newUserDefinedProviderCount = userDefinedProviderCount;

        providers.forEach(provider => {

            if (provider['provider_id'] == null) {
                provider['provider_id'] = `user_defined_${newUserDefinedProviderCount}`;
                provider['provider'] = `User Defined Provider #${newUserDefinedProviderCount + 1}`;
                provider['provider_abbr'] = `UDP${newUserDefinedProviderCount + 1}`;
                provider['provider_cost_index'] = 100;
                newUserDefinedProviderCount += 1;
            }
    
            provider['networks'] = [];

            newProviders.push(provider);
            
        });

        setUserDefinedProviderCount(newUserDefinedProviderCount);

        setSimulationInput({
            adultPediatric: simulationInput.adultPediatric
        ,   serviceTypeCategory: simulationInput.serviceTypeCategory
        ,   serviceType: simulationInput.serviceType
        ,   severity: simulationInput.severity
        ,   providers: newProviders
        ,   applyIndex: simulationInput.applyIndex
        });
    };

    const onProviderUpdate = (provider) => {
        setSimulationInput({
            adultPediatric: simulationInput.adultPediatric
        ,   serviceTypeCategory: simulationInput.serviceTypeCategory
        ,   serviceType: simulationInput.serviceType
        ,   severity: simulationInput.severity
        ,   providers: simulationInput.providers.map((p) => {
                return (p['provider_id'] !== provider['provider_id']) ? p : provider
            })
        ,   applyIndex: simulationInput.applyIndex
        });

        setNetworkCount(getNetworkCount(simulationInput.providers.map((p) => {
            return (p['provider_id'] !== provider['provider_id']) ? p : provider
        })));
    };

    const onProviderDelete = (provider) => {
        setSimulationInput({
            adultPediatric: simulationInput.adultPediatric
        ,   serviceTypeCategory: simulationInput.serviceTypeCategory
        ,   serviceType: simulationInput.serviceType
        ,   severity: simulationInput.severity
        ,   providers: simulationInput.providers.filter((p) => {
                return p['provider_id'] !== provider['provider_id']
            })
        ,   applyIndex: simulationInput.applyIndex
        });

        setNetworkCount(getNetworkCount(simulationInput.providers.filter((p) => {
            return p['provider_id'] !== provider['provider_id']
        })));
    };

    const onProviderDeleteDialog = (provider) => {
        onModalAreYouSureDialogOpen({
            'action': () => {
                onProviderDelete(provider);
            },
            'message': `Are you sure you want to DELETE ${provider['provider']} provider?`,
            'reject': "No",
            'confirm': "Yes",
        });
    };

    const isSimulationReady = () => {

        if (simulationInput.providers.length === 0) {
            return false;
        }

        for (let i = 0; i < simulationInput.providers.length; i++) {
            if (simulationInput.providers[i].networks.length > 0)
                return true;
        }

        return false;
    };

    const handleOnSimulationReset = () => {
        onSimulationReset();
        setNetworkCount(0);
    };

    // ************************************************************************
    // HealthRate Institution Index&trade;

    const handleApplyIndexChange = (checked) => {
        setApplyIndex(checked);
    };

    // ************************************************************************
    // Simulation

    const [performSimulationNow, setPerformSimulationNow] = useState(false);
    const [runningSimulation, setRunningSimulation] = useState(false);

    useEffect(() => {

        const do_fetch = async (data_sims, providers) => {

            const res = await handleAPIRequest('/api/v1/simulation', { 
                method: 'POST'
            ,   body: JSON.stringify(data_sims) 
            });
            
            const sim_results = await res.json();

            if (res.status === 200) {

                for (let i = 0; i < sim_results['results'].length; i++) {

                    sim_results['results'][i]['provider']    = providers.filter((p) => p['provider_id'] === sim_results['results'][i]['model_inputs']['provider_id'])[0];
                    sim_results['results'][i]['network']     = sim_results['results'][i]['provider'].networks.filter((n) => n['network_id'] === sim_results['results'][i]['model_inputs']['network_id'])[0];

                    // console.log(`added simulation result to queue ${my_provider['provider']} ${my_network['network']} ${JSON.stringify(data['model_outputs'])}`);
                }

                sim_results['service_type'] = simulationInput.serviceType;
                sim_results['severity'] = simulationInput.severity;

                addSimulationResult(sim_results);
                navigate(`/results/${sim_results['simulation_result_id']}`);
            }
            else {
                console.log(JSON.stringify(sim_results));
                setAlertError({
                    title: sim_results.name
                ,   body: sim_results.description.replace(/\n/g, "<br />")
                });
            }

            setRunningSimulation((r) => false);
        }

        if (performSimulationNow) {

            console.log('simulating...');

            setPerformSimulationNow(false);
            setRunningSimulation(true);

            let provider_networks = [];

            for (let i = 0; i < simulationInput.providers.length; i++) {
                for (let j = 0; j < simulationInput.providers[i].networks.length; j++) {

                    const provider_network = {
                        "provider_id" : simulationInput.providers[i].provider_id,
                        "provider_cost_index" : simulationInput.providers[i].provider_cost_index,
                        "network_id" : simulationInput.providers[i].networks[j].network_id,
                        "contract_terms" : {
                            "admin_case_rate" : simulationInput.providers[i].networks[j].admin_case_rate,
                            "facility_pct_charge" : simulationInput.providers[i].networks[j].facility_pct_charge / 100,
                            "biologic_rate_pct_wac" : simulationInput.providers[i].networks[j].biologic_rate_pct_wac / 100,
                            "outlier_threshold" : simulationInput.providers[i].networks[j].outlier_threshold,
                            "outlier_pct_charge" : simulationInput.providers[i].networks[j].outlier_pct_charge / 100,
                            "mpp_pct_charge" : simulationInput.providers[i].networks[j].mpp_pct_charge / 100,
                            "days_included" : simulationInput.providers[i].networks[j].days_included,
                            "per_diem_rate" : simulationInput.providers[i].networks[j].per_diem_rate,
                            "escalator_pct" : simulationInput.providers[i].networks[j].escalator_pct /100
                        }
                    };
                    console.log(`simulating ${JSON.stringify(provider_network)}, ${JSON.stringify(simulationInput.providers[i].provider)}, ${JSON.stringify(simulationInput.providers[i].networks[j].network)}`);

                    provider_networks.push(provider_network);

                }
            }

            const data_sims = {
                "service_type_id" : simulationInput.serviceType.service_type_id,
                "severity_id" : simulationInput.severity.severity_id,
                "severity_params" : {
                    "eval_billed_mean" : simulationInput.severity.eval_billed_mean,
                    "eval_billed_std_dev" : simulationInput.severity.eval_billed_std_dev,
                    "biologic_billed": simulationInput.severity.biologic_billed,
                    "pre_admin_billed_mean" : simulationInput.severity.pre_admin_billed_mean,
                    "pre_admin_billed_std_dev" : simulationInput.severity.pre_admin_billed_std_dev,
                    "admin_billed_mean" : simulationInput.severity.admin_billed_mean,
                    "admin_billed_std_dev" : simulationInput.severity.admin_billed_std_dev,
                    "post_admin_billed_mean" : simulationInput.severity.post_admin_billed_mean,
                    "post_admin_billed_std_dev" : simulationInput.severity.post_admin_billed_std_dev,
                    "additional_billed_mean" : simulationInput.severity.additional_billed_mean,
                    "additional_billed_std_dev" : simulationInput.severity.additional_billed_std_dev,
                    "length_of_stay_mean": simulationInput.severity.length_of_stay_mean,
                    "length_of_stay_std_dev": simulationInput.severity.length_of_stay_std_dev
                },
                "apply_provider_cost_index" : simulationInput.applyIndex,
                "provider_networks" : provider_networks
            }

            do_fetch(data_sims, simulationInput.providers);
        }

    }, [addSimulationResult, navigate, handleAPIRequest, simulationInput, performSimulationNow])

    const onSimulate = () => {
        setAlertError(null);
        setPerformSimulationNow(true);
    };

    const simulationMessages = [
        "🌐 Harnessing the might of our simulation algorithms to lock in super savings!"
    ,   "💪 Flexing our digital muscles to lift you to the peak of price perfection!"
    ,   "🧠 Smart simulation in action: Calculating the best contracts!"
    ,   "🎲 Rolling out the big guns in simulation tech to land you blockbuster savings!"
    ,   "🚀 Propelling your simulation at the speed of savings to secure the lowest price!"
    ,   "🔬 Precision simulation at work: Sifting through data to pinpoint top deals!"
    ,   "🏋️‍♂️ Our simulation is doing the heavy lifting to hoist up the best prices!"
    ,   "🧩 Piecing together the perfect price puzzle with our powerful simulation savvy!"
    ,   "📊 Deploying data-driven deal detection to deliver the lowest price!"
    ];

    // ************************************************************************
    // Modals AreYouSure

    const [modal_AreYouSure_dialog_open, setAreYouSureDialogOpen] = useState(false)
    const [modal_AreYouSure_dialog_data, setAreYouSureDialogData] = useState({})

    const onModalAreYouSureDialogOpen = (data) => {
        setAreYouSureDialogData(data)
        setAreYouSureDialogOpen(true)
    }

    
    // ************************************************************************
    // Modals ProviderSelect

    const [modal_ProviderSelect_dialog_open, setProviderSelectDialogOpen] = useState(false)

    const onModalProviderSelectDialogOpen = () => {
        setProviderSelectDialogOpen(true);
    }


    // ************************************************************************
    // Modals NetworkSelect

    const [modal_NetworkSelect_dialog_open, setNetworkSelectDialogOpen] = useState(false)
    const [modal_NetworkSelect_provider, setNetworkSelectProvider] = useState(null)

    const onModalNetworkSelectDialogOpen = (provider) => {
        setNetworkSelectProvider(provider);
        setNetworkSelectDialogOpen(true);
    }


    // ************************************************************************
    // SimulationInput Save and Load

    const onSimulationLoad = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const fileText = await file.text();
                const o = JSON.parse(fileText);

                if(!o.simulationInput || !o.simulationInput.version|| o.simulationInput.version !== currentVersion || !('userDefinedProviderCount' in o)) {
                    setAlertError ( {
                        title: 'Error Reading File: '
                        , body: 'File version does not match current version.'
                    })
                    setTimeout(() => {setAlertError(null)}, 5000);
                    return;
                }
                else {
                    setSimulationInput(o.simulationInput);
                    setUserDefinedProviderCount(o.userDefinedProviderCount);
                    
                    setAlertSuccess ({
                        title: 'Loaded File: '
                        , body: file.name
                    })
                    setTimeout(() => {setAlertSuccess(null)}, 5000);

                }

                // Clear the file input after loading
                event.target.value = null;
                
            } catch (error) {
                setAlertError ({
                    title: 'Error Reading File: '
                    , body: file.name
                })
                setTimeout(() => {setAlertError(null)}, 5000);
                console.error('Error reading file:', error);
            }   
        }
    };

    const onSimulationSave = () => {

        const o = {
            simulationInput: simulationInput
        ,   userDefinedProviderCount: userDefinedProviderCount
        }

        const blob = new Blob([JSON.stringify(o)], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
    
        const link = document.createElement('a');
        link.href = url;
        link.download = 'simulation_input.json';
        link.click();
    
        // Clean up the URL object
        URL.revokeObjectURL(url);
    };

    return (
        <>

            <Alert show={alertError} variant="danger">
                <Alert.Heading>{alertError ? alertError.title : ''}</Alert.Heading>
                <p dangerouslySetInnerHTML={alertError ? { __html: alertError.body } : { __html: '' }}></p>
            </Alert>

            <Alert show={alertSuccess} variant="success">
                <Alert.Heading>{alertSuccess ? alertSuccess.title : ''}</Alert.Heading>
                <p dangerouslySetInnerHTML={alertSuccess ? { __html: alertSuccess.body } : { __html: '' }}></p>
            </Alert>

            <AreYouSureDialog
            show={ modal_AreYouSure_dialog_open }
            onHide={ () => setAreYouSureDialogOpen(false) }
            modalData={ modal_AreYouSure_dialog_data }
            />

            <NetworkSelect
            show={ modal_NetworkSelect_dialog_open }
            onHide={ () => setNetworkSelectDialogOpen(false) }
                size="xl"
            handleAPIRequest={ handleAPIRequest }
            service_type_id={ simulationInput['serviceType'] ? simulationInput['serviceType']['service_type_id'] : null }
            provider={ modal_NetworkSelect_provider }
            onProviderUpdate={ onProviderUpdate }
            remainingNetworkCount={ maxNetworks - networkCount }
            />

            <ProviderSelect
            show={ modal_ProviderSelect_dialog_open }
            onHide={ () => setProviderSelectDialogOpen(false) }
                size="xl"
            handleAPIRequest={ handleAPIRequest }
            service_type_id={ simulationInput['serviceType'] ? simulationInput['serviceType']['service_type_id'] : null }
            selected_providers={ simulationInput.providers }
            onSelectProviders={ onProvidersCreate }
            />

            <div className='bg-light mt-4 p-3'>
                <Row>
                    <div className="col-md-6">
                        <h1>{ mode } Simulation</h1>
                    </div>
                    <div className="col-md-6 text-end align-middle">
                        <Button id="sim_save_button" onClick={ onSimulationSave } >Save</Button>
                        &nbsp;
                        <label id="sim_load_button" className="btn btn-primary">
                            Load
                            <input className="d-none" type="file" accept=".json" onChange={ onSimulationLoad } />
                        </label>
                        {/* <input className="form-control" style={{ 'width': 'unset', 'display': 'unset' }} type="file" accept=".json" onChange={onSimulationLoad}  /> */}
                        &nbsp;
                        <Button id="sim_reset_button" onClick={ handleOnSimulationReset } >Reset</Button>
                    </div>
                </Row>
            </div>

            <div className='bg-light mt-4 p-3'>
                <Row>
                    <h2>Service Type</h2>
                </Row>
                <div className="row">
                    <SimulationServiceTypeSelect element_id="serviceType" handleAPIRequest={ handleAPIRequest } simulationInput={ simulationInput } setAdultPediatric={ setAdultPediatric } setServiceTypeCategory={ setServiceTypeCategory } setServiceType={ setServiceType } />
                </div>
            </div>
            <div className='bg-light mt-4 p-3'>
                <Row>
                    <h2>Clinical Severity</h2>
                </Row>
                <div className="row">
                    <div className="col-md-8 mb-sm-2 mb-2">
                    <SimulationSeveritySelect element_id="severity" handleAPIRequest={ handleAPIRequest } simulationInput={ simulationInput } setSeverity={ setSeverity } />
                    </div>
                    <div className="col-md-4 mb-sm-2 mb-2">
                        {/*I don't love this. It is too far away from selection box and is sometimes moved to the next row on resize.*/}
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 600, hide: 400 }}
                            overlay={
                                <Tooltip id="tooltip-severity" className='custom-tooltip'>
                                    Expressed as Low, Medium, High, and Catastrophic.  The expected clinical severity or complexity of the case. Corresponding field values will be defaulted to the selected level but remain modifiable.
                                </Tooltip>
                            }
                        >
                            <span>&nbsp;<QuestionCircle id="qc-severity" /></span>
                        </OverlayTrigger>
                    </div>
                </div>
            <SimulationSeverityParams element_id="severityParams" handleAPIRequest={ handleAPIRequest } simulationInput={ simulationInput } setSeverity={ setSeverity } />
            </div>
            <div className='bg-light mt-4 p-3'>
                <Row>
                    <h2>Providers and Networks</h2>
                </Row>

                {simulationInput.providers.map((p) => (
                <SimulationProvider element_id="providers" handleAPIRequest={ handleAPIRequest } provider={ p } onProviderUpdate={ onProviderUpdate } onProviderDelete={ onProviderDeleteDialog } handleNetworkAdd={ onModalNetworkSelectDialogOpen } remainingNetworkCount={ maxNetworks - networkCount } key={ `provider_${p.provider_id}` } />
                ))}

                <div className="row border-top pt-4">
                    <div className="col-md-6 mb-sm-2 mb-2">
                        <Button id="add_provider" onClick={ onModalProviderSelectDialogOpen } disabled={ ( simulationInput.severity === null || networkCount >= maxNetworks ) } >
                            <PlusCircle /> Add Providers
                        </Button>
                        &nbsp;
                        <Button id="add_user_defined_provider" onClick={ () => onProvidersCreate( [{ provider_id: null }] ) } disabled={ ( simulationInput.severity === null || networkCount >= maxNetworks ) } variant="secondary" >
                            <PlusCircle /> Add User Defined Provider
                        </Button>
                    </div>
                </div>
            </div>

            <div className='bg-light mt-4 mb-4 p-3'>
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <Button
                            variant={simulationInput.applyIndex ? "secondary" : "outline-secondary"}
                            onClick={() => {
                                const checkbox = document.getElementById("applyIndex");
                                checkbox.checked = !checkbox.checked;
                                handleApplyIndexChange(checkbox.checked);
                            }}
                            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                        >
                            <input
                                id="applyIndex"
                                type="checkbox"
                                checked={simulationInput.applyIndex}
                                onChange={(e) => handleApplyIndexChange(e.target.checked)}
                                onClick={(e) => e.stopPropagation()}
                                style={{ marginRight: '0.5em' }}
                            />
                            <span id="apply_hii">Apply HealthRate Institution Index&trade;</span>
                        </Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                    <Button id="simulate_now" onClick={ onSimulate } disabled={ !(isSimulationReady()) } >
                            Simulate Now
                            {runningSimulation ? (
                                <>&nbsp;&nbsp;
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </>
                            ) : (<></>)}
                        </Button>
                        {runningSimulation ? (
                            <>&nbsp;&nbsp;
                                <Spinner
                                    id="loading_spinner"
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                &nbsp;&nbsp;
                            { simulationMessages[Math.floor(Math.random() * simulationMessages.length)] }
                            </>
                        ) : (<></>)}
                    </div>
                </div>
            </div>
        </>
    );
}

import Modal from 'react-bootstrap/Modal';
import CountrySearch from '../components/Country/CountrySearch';

export default function CountrySelect({ element_id, show, onHide, size, handleAPIRequest, selected_countries, onSelectCountries }) {

    if (!element_id) {
        element_id = "country-select-modal";
    }

    if (!size) {
        size = "xl";
    }

    const onSelectCountriesSave = (countries) => {
        onSelectCountries(countries);
        onHide();
    };

    return (

        <Modal
            id={ element_id }
            show={ show }
            onHide={ onHide }
            size={ size }
            aria-labelledby={ element_id }
        >
            <Modal.Header closeButton>
                <Modal.Title id={ element_id + '_title'}>
                    Add Countries
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CountrySearch element_id={ element_id + '_search' } handleAPIRequest={ handleAPIRequest } selected_countries={ selected_countries } onSelectCountries={ onSelectCountriesSave } />
            </Modal.Body>
        </Modal>

    );
}

import React, { useCallback, useEffect, useState } from 'react';

import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';

import ModeSelect from '../Mode/ModeSelect';

export default function Config({ handleAPIRequest, mode, setMode }) {

    const [alertError, setAlertError] = useState(null)

    const [customerConfig, setCustomerConfig] = useState([]);

    const fetchCustomerConfig = useCallback( async () => {
        const res = await handleAPIRequest('/api/v1/customer_config');
        const data = await res.json();

        if (res.status === 200) {
            setCustomerConfig(data);
            console.log('loaded customer config: ' + data.length);
        } 
        else {
            console.log(JSON.stringify(data));
            setAlertError({
                title: data.name
            ,   body: data.description.replace(/\n/g, "<br />")
            });
        }

    }, [handleAPIRequest, setAlertError, setCustomerConfig]);

    useEffect(() => {
        fetchCustomerConfig();
    }, [fetchCustomerConfig, handleAPIRequest]);

    return (
        <>
        <Alert show={alertError} variant="danger">
            <Alert.Heading>{alertError ? alertError.title : ''}</Alert.Heading>
            <p dangerouslySetInnerHTML={alertError ? { __html: alertError.body } : { __html: '' }}></p>
        </Alert>
        <div className='bg-light mt-4 mb-4 p-3'>
            <Row>
                <h1>Configuration</h1>
            </Row>
            <Row className='mt-3 mb-3 p-3'>

                <div className="table-responsive">
                    <table className="table text-left">

                    <tbody>
                        <tr>
                            <td style={ {"width": "30%"} }><strong>Customer</strong></td>
                            <td id="customer_name" style={ {"width": "70%"} }>{( customerConfig['customer'] && customerConfig['customer']['name'] ? customerConfig['customer']['name'] : "" )}</td>
                        </tr>
                        <tr>
                            <td><strong>Service Type Categories</strong></td>
                            <td id="customer_service_type_categories">
                                <ul>
                                    {(customerConfig['service_type_categories'] ? customerConfig['service_type_categories'].sort().map((category, index) => (
                                        <li key={index}>{category}</li>
                                    )) : "")}
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td><strong>Tenants</strong></td>
                            <td id="customer_tenants">
                                <ul>
                                    {(customerConfig['tenants'] ? customerConfig['tenants'].sort().map((tenant, index) => (
                                        <li key={index}>{tenant}</li>
                                    )) : "")}
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td><strong>Mode</strong></td>
                            <td id="customer_mode">
                                <ModeSelect element_id="mode_select" modes={ customerConfig['modes'] ? customerConfig['modes'] : ["Cost"] } mode={ mode } setMode={setMode} />
                            </td>
                        </tr>
                        <tr>
                            <td><strong>Config</strong></td>
                            <td id="customer_config">{( customerConfig['config'] ? customerConfig['config'] : "" )}</td>
                        </tr>
                        <tr>
                            <td><strong>Version</strong></td>
                            <td id="customer_version">{( customerConfig['version'] ? customerConfig['version'] : "" )}</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </Row>
        </div>
        </>
    )
}

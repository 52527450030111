import React, { useCallback, useEffect, useState } from 'react';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ToggleButton from "react-bootstrap/ToggleButton";

export default function ProviderSearch({ element_id, handleAPIRequest, service_type_id, selected_providers, onSelectProvider }) {

    const [alertError, setAlertError] = useState(null);

    const [providersTempSelect, setProvidersTempSelect] = useState([]);

    const [providersTextFilter, setProvidersTextFilter] = useState('');
    const [providersFilter, setProvidersFilter] = useState({
        service_type_id: service_type_id
    ,   state_id: null
    });

    const [providers, setProviders] = useState({
        providers: []
    });

    const [states, setStates] = useState([]);

    const fetchProviders = useCallback( () => {

        const do_fetch = async () => {

            const searchParams = { };

            for (const k in providersFilter) {
                if (providersFilter[k] && '' + providersFilter[k] !== '') {
                    searchParams[k] = providersFilter[k];
                }
            }

            let fetch_url = '/api/v1/providers';

            if (Object.keys(searchParams).length > 0) {
                fetch_url += '?';
                fetch_url += new URLSearchParams(searchParams);
            }

            console.log('fetching providers: ' + fetch_url);

            const res = await handleAPIRequest(fetch_url);
            const data = await res.json();

            if (res.status === 200) {
                setProviders({...providersFilter
                ,   providers: data['providers']
                });
                console.log('loaded providers for ' + JSON.stringify(searchParams) + ' : ' + data['providers'].length);
            } 
            else {
                console.log(JSON.stringify(data));
                setAlertError({
                    title: data.name
                ,   body: data.description.replace(/\n/g, "<br />")
                });
            }
        }

        if (    !('service_type_id' in providers) 
            ||  providersFilter['service_type_id'] !== providers['service_type_id'] 
            ||  !('state_id' in providers) 
            ||  providersFilter['state_id'] !== providers['state_id']) {
            do_fetch();
        }

    }, [handleAPIRequest, providersFilter, providers]);

    const fetchStates = useCallback( () => {
        const do_fetch = async () => {

            let fetch_url = '/api/v1/states';
            console.log('fetching states: ' + fetch_url);

            const res = await handleAPIRequest(fetch_url);
            const data = await res.json();

            if (res.status === 200) {
                setStates(data['states']);
                console.log('loaded states: ' + data['states'].length);
            } 
            else {
                console.log(JSON.stringify(data));
                setAlertError({
                    title: data.name
                ,   body: data.description.replace(/\n/g, "<br />")
                });
            }
        }

        if (states.length === 0) {
            do_fetch();
        }

    }, [handleAPIRequest, states]);

    useEffect(() => {
        fetchProviders();
        fetchStates();
    }, [fetchProviders, fetchStates, handleAPIRequest, providersFilter]);

    const isProviderSelected = (provider) => {
        return providersTempSelect.some(selectedProvider => selectedProvider.provider_id === provider.provider_id);
    };

    const handleOnSelectProvider = (checked, provider) => {
        if (checked && !isProviderSelected(provider)) {
            setProvidersTempSelect([...providersTempSelect, provider]);
        } 
        else {
            setProvidersTempSelect(providersTempSelect.filter((p) => p['provider_id'] !== provider['provider_id']));
        }
    };

    const handleOnChangeState = async (s) => {
        if (s === null) {
            setProvidersFilter({
                service_type_id: providersFilter.service_type_id
            ,   state_id: null
            });
        } 
        else {
            setProvidersFilter({
                service_type_id: providersFilter.service_type_id
            ,   state_id: parseInt(s)
            });
        }
    };

    return (
        <>
            <Alert show={alertError} variant="danger">
                <Alert.Heading>{alertError ? alertError.title : ''}</Alert.Heading>
                <p dangerouslySetInnerHTML={alertError ? { __html: alertError.body } : { __html: '' }}></p>
            </Alert>
            <Row>
                <Col>
                    <div className="input-group">
                        <span className="input-group-text">Search</span>
                        <input type="text" className="form-control" value={ providersTextFilter } onChange={ (e) => setProvidersTextFilter(e.target.value) } id={ element_id + '_textFilter' } />
                    </div>
                </Col>
                <Col>
                    <div className="input-group">
                        <span className="input-group-text">State</span>
                        <select className="form-select" id={ element_id + '_stateFilter' } onChange={(e) => { 
                            const selectedValue = e.target.value === "Select..." ? null : e.target.value;
                            handleOnChangeState(selectedValue);
                            }}
                        >
                            <option value={"Select..."} key={ element_id + '_stateFilter' + '_null' }>Select...</option>
                            {states.map((s) => (
                                <option value={ s['state_id'] } key={ element_id + '_stateFilter' + '_' + s['state_id'] }>{s['state']}</option>
                            ))}
                        </select>
                    </div>
                </Col>
            </Row>
            <Row className='mt-5 border-bottom'>
                <Col xs={2}></Col>
                <Col><strong>Name</strong></Col>
                <Col><strong>State</strong></Col>
                <Col><strong>HealthRate Institution Index&trade;</strong></Col>
                {/* <Col><strong>Services</strong></Col> */}
            </Row>
            <div id="provider_list" className='scrollable-menu border-bottom'>
            {providers['providers'].filter((p) => {
                for (var i = 0; i < selected_providers.length; i++) {
                    if (p['provider_id'] === selected_providers[i]['provider_id']) {
                        return false;
                    }
                }
                return true;
            })
            .map((p) => {

                p['search_found'] = 0;

                if (providersTextFilter === '') {
                    p['search_found'] = 1;
                    return p;
                }

                let filters = providersTextFilter.split(' ');

                for (var i = 0; i < filters.length; i++) {

                    if (filters[i] === '')
                        continue;

                    let re = new RegExp(filters[i].replace(/[\!\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g, '\\$&'), 'i');
                    const result = p['provider'].match(re);
                    if (result) 
                        p['search_found'] += 1;
                }

                return p;

            })
            .filter((p) => p['search_found'] > 0)
            .sort((a, b) => {
                if (a['search_found'] !== b['search_found']) {
                    return a['search_found'] > b['search_found'] ? -1 : 1;
                }
                return a['provider'] > b['provider'] ? 1 : -1;
            })
            .map((p) => (
                <Row key={ element_id + '_' + p['provider_id'] } className='mt-2 mb-2 pb-2 align-items-center border-bottom'>
                    <Col xs={2}>
                        <Button
                            variant={isProviderSelected(p) ? "primary" : "outline-primary"}
                            onClick={() => {
                                const checkbox = document.getElementById(element_id + '_' + p['provider_id'] + '_checkbox');
                                checkbox.checked = !checkbox.checked;
                                handleOnSelectProvider(checkbox.checked, p);
                            }}
                            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                        >
                            <input
                                id={element_id + '_' + p['provider_id'] + '_checkbox'}
                                type="checkbox"
                                checked={isProviderSelected(p)}
                                onChange={(e) => handleOnSelectProvider(e.target.checked, p)}
                                onClick={(e) => e.stopPropagation()}
                                style={{ marginRight: '0.5em' }}
                            />
                            Add
                        </Button>
                    </Col>
                    <Col>{ p['provider'] }</Col>
                    <Col>{ p['state'] }</Col>
                    <Col>{ p['provider_cost_index'] }</Col>
                </Row>
            ))}
            </div>
            <Row className="mt-3 text-end">
                <Col><Button id="save_provider" onClick={ (e) => onSelectProvider(providersTempSelect) } disabled={providersTempSelect.length === 0}>Save</Button></Col>
            </Row>
        </>
    );
}


import Modal from 'react-bootstrap/Modal';
import NetworkSearch from '../components/Network/NetworkSearch';

export default function NetworkSelect({ element_id, show, onHide, size, handleAPIRequest, service_type_id, provider, onProviderUpdate, remainingNetworkCount }) {

    if (!element_id) {
        element_id = "network-select-modal";
    }

    if (!size) {
        size = "xl";
    }

    const onProviderUpdateSave = (provider) => {
        onProviderUpdate(provider);
        onHide();
    };

    // centered

    return (

        <Modal
            id={ element_id }
            show={ show }
            onHide={ onHide }
            size={ size }
            aria-labelledby={ element_id }
        >
            <Modal.Header closeButton>
                <Modal.Title id={ element_id + '_title'}>
                    Add Networks
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <NetworkSearch element_id={ element_id + '_search' } handleAPIRequest={ handleAPIRequest } service_type_id={ service_type_id } provider={ provider } onProviderUpdate={ onProviderUpdateSave } remainingNetworkCount={remainingNetworkCount} />
            </Modal.Body>
        </Modal>

    );
}

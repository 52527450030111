import Modal from 'react-bootstrap/Modal';
import ProviderSearch from '../components/Provider/ProviderSearch';

export default function ProviderSelect({ element_id, show, onHide, size, handleAPIRequest, service_type_id, selected_providers, onSelectProviders }) {

    if (!element_id) {
        element_id = "provider-select-modal";
    }

    if (!size) {
        size = "xl";
    }

    const onSelectProviderSave = (providers) => {
        onSelectProviders(providers);
        onHide();
    };

    // centered

    return (

        <Modal
            id={ element_id }
            show={ show }
            onHide={ onHide }
            size={ size }
            aria-labelledby={ element_id }
        >
            <Modal.Header closeButton>
                <Modal.Title id={ element_id + '_title' }>
                    Add Providers
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ProviderSearch element_id={ element_id + '_search' } handleAPIRequest={ handleAPIRequest } service_type_id={ service_type_id } selected_providers={ selected_providers } onSelectProvider={ onSelectProviderSave } />
            </Modal.Body>
        </Modal>

    );
}

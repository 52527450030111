import React, { useEffect } from 'react';

export default function Login({ setTokenAccess, setTokenRefresh, setNonce, nonce }) {

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const redirect_uri = window.location.origin + window.location.pathname;

    const goto_auth = async () => {
        try {
            const response = await fetch('/api/v1/login?redirect_uri=' + encodeURIComponent(redirect_uri));
            const data = await response.json();

            const url = new URL(data);
            const nonceNew = url.searchParams.get('nonce');
            setNonce(nonceNew);

            window.location.href = data;

            console.log('URL:', data);
            console.log('Nonce:', nonceNew);

        } catch (error) {
            console.error('Error:', error);
        }
    }

    const handleLogin = async () => {

        if (code) {

            console.log('Code:', code);

            try {
                const response = await fetch('/api/v1/callback', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({code, nonce, redirect_uri})
                });

                // if (response.status === 401) {
                //     return goto_auth();
                // }

                if (response.status !== 200) {
                    console.error('Error:', response.statusText);
                    return;
                }
            
                const data = await response.json();
                console.log('Callback data:', data);
                console.log('Access Token:', data.access_token);
                console.log('Refresh Token:', data.refresh_token);
            
                setTokenAccess(data.access_token);
                setTokenRefresh(data.refresh_token);
                window.history.replaceState({}, document.title, window.location.pathname);


            } catch (error) {
                console.error('Error:', error);
            }
            
        }
        else {
            goto_auth();
        }

    };

    useEffect(() => {
        handleLogin();
    }, []);

    return (
        <></>
    )
}

import React, { useEffect, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Row from 'react-bootstrap/Row';
import './SimulationResults.css'
import ToggleButton from "react-bootstrap/ToggleButton";
import useFormatters from '../../hooks/useFormatters';
import { Printer } from 'react-bootstrap-icons';

import PlotlyChart from '../Plotly/PlotlyChart';

export default function SimulationResults({ simulationResultsCache, setChatBotContext, mode }) {

    const [alertError] = useState(null)

    const { simulation_result_id } = useParams();

    const radiosGraph = [
        { name: 'Severity Simulation', value: "monte_carlo" },
        { name: 'Billing Range Simulation', value: "full_distribution" },
    ];

    const [simulationGraph, setSimulationGraph] = useState("monte_carlo");

    const [simulationResultSelect, setSimulationResultSelect] = useState('result_3');

    const [simulationResults, setSimulationResults] = useState(undefined);

    const [simulationBenchmarkCount, setSimulationBenchmarkCount] = useState(0);


    useEffect(() => {
        
        const simulationResultsCacheMatches = simulationResultsCache.filter((r) => r['simulation_result_id'] === simulation_result_id);

        if (simulationResultsCacheMatches && simulationResultsCacheMatches.length > 0)
        {

            const result = JSON.parse(JSON.stringify(simulationResultsCacheMatches[0])); 

            result['results'].sort((a, b) => {
                if (a['model_outputs']['monte_carlo']['result_3']['value']['total_allowed']['v'] > b['model_outputs']['monte_carlo']['result_3']['value']['total_allowed']['v']) {
                    return ( mode === 'Cost' ? 1 : -1);
                } else if (a['model_outputs']['monte_carlo']['result_3']['value']['total_allowed']['v'] < b['model_outputs']['monte_carlo']['result_3']['value']['total_allowed']['v']) {
                    return ( mode === 'Cost' ? -1 : 1);
                } else {
                    return 0;
                }
            });

            // if (result['benchmarks']) {
            //     result['benchmarks'].forEach(b => {
            //         result['results'].unshift(b);
            //     });
            //     setSimulationBenchmarkCount(result['benchmarks'].length);
            // }

            while (result['results'].length > 5) {
                result['results'].pop();
            }

            setSimulationResults(result);

        } 
        else {
            setSimulationResults(undefined);
        }

        console.log(simulationResultsCacheMatches[0]);
    }, [simulationResultsCache]);

    // ************************************************************************
    // Simulation Results Display and Formatters

    const adjustSimulationResultsForDisplay = (v) => {
        if (v['v'] < .03) return 3;
        return v['v'] * 100;
    };

    const simulationResultVariant = (v, mode) => {
        if (mode === 'Cost') {
            if (v['max'] === 0 || v['v'] === v['min']) {
                return "success";         
            } else if (v['v'] === v['max']) {
                return "danger";
            } else {
                return "warning";
            }
        } else if (mode === 'Reimbursement') {
            if (v['v'] === v['max']) {
                return "success";
            } else if (v['min'] === 0 || v['v'] === v['min']) {
                return "danger";
            } else {
                return "warning";    
            }   
        } else {
            return "warning";
        }
    }
    
    const simulationDifferenceDisplay = (simulationResult, showSavings = false) => {
        if (mode === 'Cost') {
            return simulationResult['max_cnt'] === simulationResult['total_cnt'] ? (
                    <>Equal Cost</>
                ) : (
                        <>
                        { simulationResult['v'] < 1 ? (
                            <>{ Math.floor((1 - simulationResult['v']) * 1000) / 10 }% Savings
                            { showSavings === true ? (<> ({ currencyFormatter.format(Math.abs(simulationResult['v_diff_Cost'])) })</> ) : (<></>) }
                            </>
                        ) : (
                            <>Max Cost</>
                        )}
                        <ProgressBar now={ adjustSimulationResultsForDisplay(simulationResult) } variant={ simulationResultVariant(simulationResult, mode) } />
                        { simulationResult['max'] > 0 && simulationResult['min_cnt'] <= 1 && simulationResult['v'] === simulationResult['min'] ? (
                                <strong>Best Value</strong>
                            ) : (<></>)}
                        </>
                )
            ;
        } else if (mode === 'Reimbursement') {
            return simulationResult['max_cnt'] === simulationResult['total_cnt'] ? (
                <>Equal Cost</>
            ) : (
                    <>
                    { simulationResult['v'] === simulationResult['min'] ?(
                        <>Min Reimbursement</>
                    ) 
                    : (
                        <>{ Math.floor(simulationResult['v_Reimbursement'] * 1000) / 10 }% Increase
                        { showSavings === true ? (<> ({ currencyFormatter.format(Math.abs(simulationResult['v_diff_Reimbursement'])) })</> ) : (<></>) }
                        </>
                    )}
                    <ProgressBar now={ adjustSimulationResultsForDisplay(simulationResult) } variant={ simulationResultVariant(simulationResult, mode) } />
                    { simulationResult['max'] > 0 && simulationResult['min_cnt'] <= 1 && simulationResult['v'] === simulationResult['max'] ? (
                            <strong>Best Return</strong>
                        ) : (<></>)}
                    </>
                )
            ;
        }
    }

    // ************************************************************************
    // Simulation Results Graphs
    
    const handleOnSimulationGraphChange = (e) => {
        setSimulationGraph(e.target.value);
        setSimulationResultSelect('result_3');
    }
    
    const calculateAverage = (data) => {
        const sum = data.reduce((acc, value) => acc + value, 0);
        return sum / data.length;
    };

    const simulationDataHistogram = (r) => {
        const totalAllowed = r['graph_output']['monte_carlo'];
        const average = calculateAverage(totalAllowed);

        return {
            x: totalAllowed,
            name: `${r['provider']['provider_abbr']}: ${r['network']['network']}`,
            type: "histogram",
            histnorm: "percent",
            opacity: 0.75,
            xbins: {
                start: Math.min(...totalAllowed),
                end: Math.max(...totalAllowed),
                size: average / 100 // Adjust the bin size as needed
            },
            hoverinfo: "x+y",
        }
    }

    const simulationDataLine = (r) => {
        const totalBilled = r['graph_output']['full_distribution']['total_billed'];
        const totalAllowed = r['graph_output']['full_distribution']['total_allowed'];

        return {
            x: totalBilled,
            y: totalAllowed,
            name: `${r['provider']['provider_abbr']}: ${r['network']['network']}`,
            type: "scatter",
            hoverinfo: "x+y",
        }
    }

    useEffect(() => {

        if (simulationResults && simulationResults['results'][simulationBenchmarkCount] && simulationResults['results'][simulationBenchmarkCount]['provider']) {
            
            let new_chat_bot_context = "";

            new_chat_bot_context += `Simulation ${simulationResults['simulation_result_id']} found the best value for Service Type ${simulationResults['service_type']['service_type']} with Severity ${simulationResults['severity']['severity']} is Provider ${simulationResults['results'][simulationBenchmarkCount]['provider']['provider']} with Network ${simulationResults['results'][simulationBenchmarkCount]['network']['network']}`;

            setChatBotContext(new_chat_bot_context);
            console.log(`setting chat bot context... ${JSON.stringify(new_chat_bot_context)}`)    
        }

    }, [simulationResults, simulationBenchmarkCount]);

    const { currencyFormatter, percentageFormatter, numberFormatter } = useFormatters();

    const onPrint = () => {
        window.print();
    }    

    return (

        <>

        <Alert show={alertError} variant="danger">
            <Alert.Heading>{alertError ? alertError.title : ''}</Alert.Heading>
            <p dangerouslySetInnerHTML={alertError ? { __html: alertError.body } : { __html: '' }}></p>
        </Alert>

        { simulationResults ? (

            <div className='bg-light mt-4 mb-4 p-3'>
                
                <Row>
                    <div className="col-md-11">
                        <h1 id="simulation_results_page_header">{ mode } Simulation Results</h1>
                    </div>
                    <div className="col-md-1 text-end align-middle">
                        <Button id="print_simulation" onClick={onPrint} className='cursor-pointer d-print-none'><Printer/></Button>
                    </div>
                </Row>

                <div className="row mt-4">
                    <div className="col-md-8">
                        <h2>{simulationResults['service_type']['service_type']} |  {simulationResults['severity']['severity']} Severity</h2>
                    </div>
                    <div className="col-md-4 text-end">                    
                        <ButtonGroup>
                            {radiosGraph.map((radio, idx) => (
                            <ToggleButton
                                key={idx}
                                id={`radio-${idx}`}
                                type="radio"
                                variant={idx % 2 ? 'outline-primary' : 'outline-primary'}
                                name="radio"
                                value={radio.value}
                                checked={simulationGraph === radio.value}
                                onChange={handleOnSimulationGraphChange}
                            >
                                <span id={`simulation_plot_${idx}`}>{radio.name}</span>
                            </ToggleButton>
                            ))}
                        </ButtonGroup>
                    </div>
                </div>

                <div className="mt-4 mb-4 border">
                    <PlotlyChart 
                        id="simulation_results_chart"
                        isVisible={ simulationGraph === "monte_carlo"}
                        chartHeight={'500px'}
                        data={simulationResults['results'].map((r) => (
                            simulationDataHistogram(r)
                        ))}
                        layout={{ 
                            autosize: true,
                            margin: {
                                l: 50,
                                r: 50,
                                t: 50,
                                b: 50
                            },
                            barmode: "overlay",
                            title: {
                                text: `${simulationResults['severity']['severity']} Severity Simulation`,
                                x: 0.5,
                                font: { 
                                    size: 24,
                                }
                            },
                            xaxis: { 
                                title: "Total Simulated Cost", 
                                auotmargin: true
                            }, 
                            yaxis: { 
                                title: "Percent of Simulations", 
                                auotmargin: true 
                            }, 
                            font: { 
                                family : "NotoSans", 
                                color: "#25194f",
                                weight: "bold",
                                size: 12
                            },  
                            colorway: ['#027a48', '#0067a0', '#00d8ff', '#4828b5', '#c80064', '#a0a000'],
                            showlegend: true,
                            legend: {
                                x: 1,
                                xanchor: 'left',
                                y: 1,
                                yanchor: 'top',
                                font: {
                                    size: 12,
                                }
                            },
                        }}
                    />
                    <PlotlyChart isVisible={ simulationGraph === "full_distribution" }
                        chartHeight={'500px'}
                        data={simulationResults['results'].map((r) => (
                            simulationDataLine(r)
                        ))}
                        layout={{ 
                            autosize: true,
                            margin: {
                                l: 50,
                                r: 50,
                                t: 50,
                                b: 50
                            },
                            title: {
                                text: `Billing Range Simulation`,
                                x: 0.5,
                                font: { 
                                    size: 24,
                                }
                            },
                            xaxis: { 
                                title: "Total Billed", 
                                auotmargin: true
                            }, 
                            yaxis: { 
                                title: "Total Simulated Cost", 
                                auotmargin: true 
                            }, 
                            font: { 
                                family : "NotoSans", 
                                color: "#25194f",
                                weight: "bold",
                                size: 12
                            },
                            colorway: ['#027a48', '#0067a0', '#00d8ff', '#4828b5', '#c80064', '#a0a000'],
                            showlegend: true,
                            legend: {
                                x: 1,
                                xanchor: 'left',
                                y: 1,
                                yanchor: 'top',
                                font: {
                                    size: 12,
                                }
                            },
                        }}
                    />
                </div>

                <div className="table-responsive">
                <table id="simulation_results_table" className={`table text-center ${simulationBenchmarkCount > 0 ? 'simulation-results-table-benchmark' : 'simulation-results-table' }`} style={ {"tableLayout": "fixed"} }>

                <thead>
                    <tr>
                        <th scope="col">
                            <div style={{ marginBottom: "0.3em" }}>
                                { simulationGraph === "monte_carlo" ? ( <strong>Results Percentile</strong> ) : ( <strong>Total Billed</strong> ) }
                            </div>
                            <div style={{ marginBottom: "0.5em" }}>
                                <select id="result_select" className="form-select"
                                    value={simulationResultSelect} 
                                    onChange={(e) => {setSimulationResultSelect(e.target.value)}}
                                    style={{ width: "80%", margin: "0 auto" }}
                                >
                                    <option value="result_0">{ simulationResults['results'][0]['model_outputs'][simulationGraph]['result_0']['label'] }</option>
                                    <option value="result_1">{ simulationResults['results'][0]['model_outputs'][simulationGraph]['result_1']['label'] }</option>
                                    <option value="result_2">{ simulationResults['results'][0]['model_outputs'][simulationGraph]['result_2']['label'] }</option>
                                    <option value="result_3">{ simulationResults['results'][0]['model_outputs'][simulationGraph]['result_3']['label'] }</option>
                                    <option value="result_4">{ simulationResults['results'][0]['model_outputs'][simulationGraph]['result_4']['label'] }</option>
                                    <option value="result_5">{ simulationResults['results'][0]['model_outputs'][simulationGraph]['result_5']['label'] }</option>
                                    <option value="result_6">{ simulationResults['results'][0]['model_outputs'][simulationGraph]['result_6']['label'] }</option>
                                    <option value="result_7">{ simulationResults['results'][0]['model_outputs'][simulationGraph]['result_7']['label'] }</option>
                                </select>
                            </div>
                        </th>
                        {simulationResults['results'].map((r) => (
                            <th scope="col" key={ `${r['model_inputs']['service_type_id']}_${r['model_inputs']['severity_id']}_${r['model_inputs']['provider_id']}_${r['model_inputs']['network_id']}_name` }>{r['provider']['provider']}{r['network'] ? `: ${r['network']['network']}` : ''}</th>
                        ))}
                    </tr>
                </thead>

                <tbody>

                    {/* Simulated Charges */}

                    <tr className="table-primary">
                        <th scope="row" className="text-start"><strong>Total Simulated Cost</strong></th>
                        {simulationResults['results'].map((r) => (
                            <td scope="col" key={ `${r['model_inputs']['service_type_id']}_${r['model_inputs']['severity_id']}_${r['model_inputs']['provider_id']}_${r['model_inputs']['network_id']}_outputs_total_allowed` }>
                                { simulationDifferenceDisplay(r['model_outputs'][simulationGraph][simulationResultSelect]['value']['total_allowed'], true) }
                            </td>
                        ))}
                    </tr>

                    <tr>
                        <th scope="row" className="text-start">&nbsp;Pre-Administration Cost</th>
                        {simulationResults['results'].map((r) => (
                            <td key={ `${r['model_inputs']['service_type_id']}_${r['model_inputs']['severity_id']}_${r['model_inputs']['provider_id']}_${r['model_inputs']['network_id']}_outputs_pre_admin_allowed` }>
                                { simulationDifferenceDisplay(r['model_outputs'][simulationGraph][simulationResultSelect]['value']['pre_admin_allowed']) }
                            </td>
                        ))}
                    </tr>

                    <tr>
                        <th scope="row" className="text-start">&nbsp;Transplant / Administration Cost</th>
                        {simulationResults['results'].map((r) => (
                            <td key={ `${r['model_inputs']['service_type_id']}_${r['model_inputs']['severity_id']}_${r['model_inputs']['provider_id']}_${r['model_inputs']['network_id']}_outputs_admin_allowed` }>
                                { simulationDifferenceDisplay(r['model_outputs'][simulationGraph][simulationResultSelect]['value']['admin_allowed']) }
                            </td>
                        ))}
                    </tr>

                    <tr>
                        <th scope="row" className="text-start">&nbsp;Post-Administration Cost</th>
                        {simulationResults['results'].map((r) => (
                            <td key={ `${r['model_inputs']['service_type_id']}_${r['model_inputs']['severity_id']}_${r['model_inputs']['provider_id']}_${r['model_inputs']['network_id']}_outputs_post_admin_allowed` }>
                                { simulationDifferenceDisplay(r['model_outputs'][simulationGraph][simulationResultSelect]['value']['post_admin_allowed']) }
                            </td>
                        ))}
                    </tr>

                    <tr>
                        <th scope="row" className="text-start">&nbsp;Per Diem Cost</th>
                        {simulationResults['results'].map((r) => (
                            <td key={ `${r['model_inputs']['service_type_id']}_${r['model_inputs']['severity_id']}_${r['model_inputs']['provider_id']}_${r['model_inputs']['network_id']}_outputs_per_diem_allowed` }>
                                { 'per_diem_allowed' in r['model_outputs'][simulationGraph][simulationResultSelect]['value'] ? simulationDifferenceDisplay(r['model_outputs'][simulationGraph][simulationResultSelect]['value']['per_diem_allowed']) : 'Not Applicable' }
                            </td>
                        ))}
                    </tr>

                    <tr>
                        <th scope="row" className="text-start">&nbsp;Biologic</th>
                        {simulationResults['results'].map((r) => (
                            <td key={ `${r['model_inputs']['service_type_id']}_${r['model_inputs']['severity_id']}_${r['model_inputs']['provider_id']}_${r['model_inputs']['network_id']}_outputs_biologic_allowed` }>
                                { simulationDifferenceDisplay(r['model_outputs'][simulationGraph][simulationResultSelect]['value']['biologic_allowed']) }
                            </td>
                        ))}
                    </tr>

                    <tr>
                        <th scope="row" className="text-start">&nbsp;Outlier Charge Triggered</th>
                        {simulationResults['results'].map((r) => (
                            <td key={ `${r['model_inputs']['service_type_id']}_${r['model_inputs']['severity_id']}_${r['model_inputs']['provider_id']}_${r['model_inputs']['network_id']}_outputs_outlier_triggered` }>
                                { r['model_outputs'][simulationGraph][simulationResultSelect]['value']['outlier_triggered'] > 0 ? (
                                    <>YES</>
                                    ) : ( 
                                        <>NO</>
                                    )
                                }
                            </td>
                        ))}
                    </tr>

                    <tr>
                        <th scope="row" className="text-start">&nbsp;Minimum Payment Provision Triggered</th>
                        {simulationResults['results'].map((r) => (
                            <td key={ `${r['model_inputs']['service_type_id']}_${r['model_inputs']['severity_id']}_${r['model_inputs']['provider_id']}_${r['model_inputs']['network_id']}_outputs_mpp_triggered` }>
                                { r['model_outputs'][simulationGraph][simulationResultSelect]['value']['mpp_triggered'] > 0 ? (
                                    <>YES</>
                                    ) : ( 
                                        <>NO</>
                                    )
                                }
                            </td>
                        ))}
                    </tr>


                    {/* Core Terms */}

                    <tr className="table-secondary">
                        <th scope="row" className="text-start"><strong>Core Terms</strong></th>
                        {simulationResults['results'].map((r) => (
                            <td scope="col" key={ `${r['model_inputs']['service_type_id']}_${r['model_inputs']['severity_id']}_${r['model_inputs']['provider_id']}_${r['model_inputs']['network_id']}_core_terms` }></td>
                        ))}
                    </tr>

                    <tr>
                        <th scope="row" className="text-start">&nbsp;Case Rate</th>
                        {simulationResults['results'].map((r) => (
                            <td key={ `${r['model_inputs']['service_type_id']}_${r['model_inputs']['severity_id']}_${r['model_inputs']['provider_id']}_${r['model_inputs']['network_id']}_admin_case_rate` }>
                                { r['model_inputs']['contract_terms'] ? currencyFormatter.format(r['model_inputs']['contract_terms']['admin_case_rate']) : '' }
                            </td>
                        ))}
                    </tr>

                    <tr>
                        <th scope="row" className="text-start">&nbsp;Facility Rate</th>
                        {simulationResults['results'].map((r) => (
                            <td key={ `${r['model_inputs']['service_type_id']}_${r['model_inputs']['severity_id']}_${r['model_inputs']['provider_id']}_${r['model_inputs']['network_id']}_facility_pct_charge` }>
                                { r['model_inputs']['contract_terms'] ? percentageFormatter.format(r['model_inputs']['contract_terms']['facility_pct_charge']) : '' }
                            </td>
                        ))}
                    </tr>

                    <tr>
                        <th scope="row" className="text-start">&nbsp;Biologic Rate as a % of WAC</th>
                        {simulationResults['results'].map((r) => (
                            <td key={ `${r['model_inputs']['service_type_id']}_${r['model_inputs']['severity_id']}_${r['model_inputs']['provider_id']}_${r['model_inputs']['network_id']}_biologic_rate_pct_wac` }>
                                { r['model_inputs']['contract_terms'] ? percentageFormatter.format(r['model_inputs']['contract_terms']['biologic_rate_pct_wac']) : '' }
                            </td>
                        ))}
                    </tr>

                    <tr>
                        <th scope="row" className="text-start">&nbsp;Outlier Charge Threshold</th>
                        {simulationResults['results'].map((r) => (
                            <td key={ `${r['model_inputs']['service_type_id']}_${r['model_inputs']['severity_id']}_${r['model_inputs']['provider_id']}_${r['model_inputs']['network_id']}_outlier_threshold` }>
                                { r['model_inputs']['contract_terms'] ? currencyFormatter.format(r['model_inputs']['contract_terms']['outlier_threshold']) : '' }
                            </td>
                        ))}
                    </tr>

                    <tr>
                        <th scope="row" className="text-start">&nbsp;Outlier Rate</th>
                        {simulationResults['results'].map((r) => (
                            <td key={ `${r['model_inputs']['service_type_id']}_${r['model_inputs']['severity_id']}_${r['model_inputs']['provider_id']}_${r['model_inputs']['network_id']}_outlier_pct_charge` }>
                                { r['model_inputs']['contract_terms'] ? percentageFormatter.format(r['model_inputs']['contract_terms']['outlier_pct_charge']) : '' }
                            </td>
                        ))}
                    </tr>

                    <tr>
                        <th scope="row" className="text-start">&nbsp;Minimum Payment Provision</th>
                        {simulationResults['results'].map((r) => (
                            <td key={ `${r['model_inputs']['service_type_id']}_${r['model_inputs']['severity_id']}_${r['model_inputs']['provider_id']}_${r['model_inputs']['network_id']}_mpp_pct_charge` }>
                                { r['model_inputs']['contract_terms'] ? percentageFormatter.format(r['model_inputs']['contract_terms']['mpp_pct_charge']) : '' }
                            </td>
                        ))}
                    </tr>

                    <tr>
                        <th scope="row" className="text-start">&nbsp;Inlier Days</th>
                        {simulationResults['results'].map((r) => (
                            <td key={ `${r['model_inputs']['service_type_id']}_${r['model_inputs']['severity_id']}_${r['model_inputs']['provider_id']}_${r['model_inputs']['network_id']}_days_included` }>
                                { r['model_inputs']['contract_terms'] ? numberFormatter.format(r['model_inputs']['contract_terms']['days_included']) : '' }
                            </td>
                        ))}
                    </tr>

                    <tr>
                        <th scope="row" className="text-start">&nbsp;Per Diem</th>
                        {simulationResults['results'].map((r) => (
                            <td key={ `${r['model_inputs']['service_type_id']}_${r['model_inputs']['severity_id']}_${r['model_inputs']['provider_id']}_${r['model_inputs']['network_id']}_per_diem_rate` }>
                                { r['model_inputs']['contract_terms'] ? currencyFormatter.format(r['model_inputs']['contract_terms']['per_diem_rate']) : '' }
                            </td>
                        ))}
                    </tr>

                    <tr>
                        <th scope="row" className="text-start">&nbsp;Escalator</th>
                        {simulationResults['results'].map((r) => (
                            <td key={ `${r['model_inputs']['service_type_id']}_${r['model_inputs']['severity_id']}_${r['model_inputs']['provider_id']}_${r['model_inputs']['network_id']}_escalator_pct` }>
                                { r['model_inputs']['contract_terms'] ? percentageFormatter.format(r['model_inputs']['contract_terms']['escalator_pct']) : '' }
                            </td>
                        ))}
                    </tr>

                    {/* Core Services */}

                    <tr className="table-secondary">
                        <th scope="row" className="text-start"><strong>Core Services</strong></th>
                        {simulationResults['results'].map((r) => (
                            <td scope="col" key={ `${r['model_inputs']['service_type_id']}_${r['model_inputs']['severity_id']}_${r['model_inputs']['provider_id']}_${r['model_inputs']['network_id']}_core_service` }></td>
                        ))}
                    </tr>

                    { simulationResults['apply_provider_cost_index'] === true ? ((
                        <tr className="table-primary">
                            <th scope="row" className="text-start"><strong>HealthRate Institution Index&trade;</strong></th>
                            {simulationResults['results'].map((r) => (
                            <td key={ `${r['model_inputs']['service_type_id']}_${r['model_inputs']['severity_id']}_${r['model_inputs']['provider_id']}_${r['model_inputs']['network_id']}_provider_cost_index` }>
                                { r['model_inputs']['provider_cost_index'] ? numberFormatter.format(r['model_inputs']['provider_cost_index']) : '' }
                            </td>
                        ))}
                        </tr>
                    )) : (<></>) }

                    <tr>
                        <th scope="row" className="text-start">&nbsp;Evaluation Cost</th>
                        {simulationResults['results'].map((r) => (
                            <td key={ `${r['model_inputs']['service_type_id']}_${r['model_inputs']['severity_id']}_${r['model_inputs']['provider_id']}_${r['model_inputs']['network_id']}_eval_billed` }>
                                { r['model_inputs']['severity_params'] ? currencyFormatter.format(r['model_inputs']['severity_params']['eval_billed']) : '' }
                            </td>
                        ))}
                    </tr>

                    <tr>
                        <th scope="row" className="text-start">&nbsp;Pre-Administration Cost</th>
                        {simulationResults['results'].map((r) => (
                            <td key={ `${r['model_inputs']['service_type_id']}_${r['model_inputs']['severity_id']}_${r['model_inputs']['provider_id']}_${r['model_inputs']['network_id']}_pre_admin_billed` }>
                                { r['model_inputs']['severity_params'] ? currencyFormatter.format(r['model_inputs']['severity_params']['pre_admin_billed']) : '' }
                            </td>
                        ))}
                    </tr>

                    <tr>
                        <th scope="row" className="text-start">&nbsp;Transplant / Administration Cost</th>
                        {simulationResults['results'].map((r) => (
                            <td key={ `${r['model_inputs']['service_type_id']}_${r['model_inputs']['severity_id']}_${r['model_inputs']['provider_id']}_${r['model_inputs']['network_id']}_admin_billed` }>
                                { r['model_inputs']['severity_params'] ? currencyFormatter.format(r['model_inputs']['severity_params']['admin_billed']) : '' }
                            </td>
                        ))}
                    </tr>

                    <tr>
                        <th scope="row" className="text-start">&nbsp;Post-Administration Cost</th>
                        {simulationResults['results'].map((r) => (
                            <td key={ `${r['model_inputs']['service_type_id']}_${r['model_inputs']['severity_id']}_${r['model_inputs']['provider_id']}_${r['model_inputs']['network_id']}_post_admin_billed` }>
                                { r['model_inputs']['severity_params'] ? currencyFormatter.format(r['model_inputs']['severity_params']['post_admin_billed']) : '' }
                            </td>
                        ))}
                    </tr>

                    <tr>
                        <th scope="row" className="text-start">&nbsp;Additional Cost</th>
                        {simulationResults['results'].map((r) => (
                            <td key={ `${r['model_inputs']['service_type_id']}_${r['model_inputs']['severity_id']}_${r['model_inputs']['provider_id']}_${r['model_inputs']['network_id']}_additional_billed` }>
                                { r['model_inputs']['severity_params'] ? currencyFormatter.format(r['model_inputs']['severity_params']['additional_billed']) : '' }
                            </td>
                        ))}
                    </tr>

                    <tr>
                        <th scope="row" className="text-start">&nbsp;Biologic</th>
                        {simulationResults['results'].map((r) => (
                            <td key={ `${r['model_inputs']['service_type_id']}_${r['model_inputs']['severity_id']}_${r['model_inputs']['provider_id']}_${r['model_inputs']['network_id']}_biologic_billed` }>
                                { r['model_inputs']['severity_params'] ? currencyFormatter.format(r['model_inputs']['severity_params']['biologic_billed']) : '' }
                            </td>
                        ))}
                    </tr>

                    <tr>
                        <th scope="row" className="text-start">&nbsp;Length of Stay</th>
                        {simulationResults['results'].map((r) => (
                            <td key={ `${r['model_inputs']['service_type_id']}_${r['model_inputs']['severity_id']}_${r['model_inputs']['provider_id']}_${r['model_inputs']['network_id']}_length_of_stay` }>
                                { r['model_inputs']['severity_params'] ? numberFormatter.format(r['model_inputs']['severity_params']['length_of_stay']) : '' }
                            </td>
                        ))}
                    </tr>

                </tbody>

                </table>
                </div>
            </div>
        ) : (
        
            <div className='bg-light mt-4 mb-4 p-3'>
                <Row>
                    <h1>Available { mode } Simulation Results</h1>
                </Row>

                <ul id="simulation_result_list">
                    {simulationResultsCache.map((s, s_idx) => (
                        <li key={ `${s['simulation_result_id']}_${s_idx}` }>
                            <a href={ '/results/' + s['simulation_result_id'] }>Service: { s['severity']['service_type']}; Severity: {s['severity']['severity'] }{ s['apply_provider_cost_index'] === true ? "; HealthRate Institution Index\u2122" : "" }</a>
                            <ul>
                                { s['results'].map((r, r_idx) => (
                                <li key={ `${r['simulation_result_id']}_${s['simulation_result_id']}_${r_idx}` }>
                                    { r['network']['provider'] } : { r['network']['network'] }
                                    {/* { JSON.stringify(s['results']) } */}
                                </li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ul>

            </div>
        )
        }

        </>

    )

}


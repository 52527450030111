import React, { useCallback, useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function CountrySearch({ element_id, handleAPIRequest, selected_countries, onSelectCountries }) {

    const [alertError, setAlertError] = useState(null);

    const [selectAllCountries, setSelectAllCountries] = useState(false);
    const [countriesTempSelect, setCountriesTempSelect] = useState([]);

    const [countriesTextFilter, setCountriesTextFilter] = useState('');

    const [countries, setCountries] = useState({
        countries: []
    });

    const fetchCountries = useCallback( () => {
        const do_fetch = async () => {

            let fetch_url = '/api/v1/international_quote';
            console.log('fetching countries: ' + fetch_url);

            const res = await handleAPIRequest(fetch_url);
            const data = await res.json();

            if (res.status === 200) {
                setCountries({
                    countries: data['countries']
                });
                console.log('loaded countries: ' + data['countries'].length);
            } 
            else {
                console.log(JSON.stringify(data));
                setAlertError({
                    title: data.name
                ,   body: data.description.replace(/\n/g, "<br />")
                });
            }
        }

        if (countries['countries'].length === 0) {
            do_fetch();
        }
    }, [handleAPIRequest, countries]);

    useEffect(() => {
        fetchCountries();
    }, [fetchCountries, handleAPIRequest]);

    const isCountrySelected = (country) => {
        return countriesTempSelect.some(selectedCountry => selectedCountry.international_quote_id === country.international_quote_id);
    };

    const handleOnSelectCountry = (checked, country) => {
        if (checked && !isCountrySelected(country)) {
            setCountriesTempSelect([...countriesTempSelect, country]);
        } 
        else {
            setCountriesTempSelect(countriesTempSelect.filter((c) => c['international_quote_id'] !== country['international_quote_id']));
            setSelectAllCountries(false);
        }

    };

    const handleSelectAllCountries = (checked) => {
        const allCountries = countries['countries'].filter((c) => {
            return !selected_countries.some((i) => i['international_quote_id'] === c['international_quote_id']);
        });
        
        if (checked) {
            setCountriesTempSelect([...allCountries]);
        } 
        else {
            setCountriesTempSelect([]);
        }

        setSelectAllCountries(checked);
    };

    return (
        <>
            <Alert show={alertError} variant="danger">
                <Alert.Heading>{alertError ? alertError.title : ''}</Alert.Heading>
                <p dangerouslySetInnerHTML={alertError ? { __html: alertError.body } : { __html: '' }}></p>
            </Alert>

            { countries['countries'].length === 0 ? (
                <Row className='mt-3 mb-3'><Col><h4>No Countries Available</h4></Col></Row>
            ) : (
                <>
                <Row>
                    <Col>
                        <div className="input-group">
                            <span className="input-group-text">Search</span>
                            <input type="text" className="form-control" value={ countriesTextFilter } onChange={ (e) => setCountriesTextFilter(e.target.value) } id={ element_id + '_textFilter' } />
                        </div>
                    </Col>
                </Row>
                <Row className='mt-3 mb-3 pb-2 align-items-center border-bottom'>
                    <Col xs={2}>
                        <Button
                            variant={selectAllCountries ? "primary" : "outline-primary"}
                            onClick={() => {
                                const checkbox = document.getElementById(element_id + '_all_countries_checkbox');
                                checkbox.checked = !checkbox.checked;
                                handleSelectAllCountries(checkbox.checked);
                            }}
                            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                        >
                            <input
                                id={element_id + '_all_countries_checkbox'}
                                type="checkbox"
                                checked={selectAllCountries}
                                onChange={(e) => handleSelectAllCountries(e.target.checked)}
                                onClick={(e) => e.stopPropagation()}
                                style={{ marginRight: '0.5em' }}
                            />
                            <span id={element_id + '_all_countries_add'}>Add All</span>
                        </Button>
                    </Col>
                    <Col><strong>Name</strong></Col>
                </Row>
                <div id="country_list" className="scrollable-menu border-bottom">
                {countries['countries'].filter((c) => {
                    return !selected_countries.some((i) => i['international_quote_id'] === c['international_quote_id']);
                })
                .map((c) => {

                    c['search_found'] = 0;

                    if (countriesTextFilter === '') {
                        c['search_found'] = 1;
                        return c;
                    }

                    let filters = countriesTextFilter.split(' ');

                    for (let i = 0; i < filters.length; i++) {
                        if (filters[i] === '')
                            continue;

                        let re = new RegExp(filters[i].replace(/[\!\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g, '\\$&'), 'i');
                        const result = c['country'].match(re);
                        if (result) 
                            c['search_found'] += 1;
                    }

                    return c;

                })
                .filter((c) => c['search_found'] > 0)
                .sort((a, b) => {
                    if (a['search_found'] !== b['search_found']) {
                        return a['search_found'] > b['search_found'] ? -1 : 1;
                    }
                    return a['country'] > b['country'] ? 1 : -1;
                })
                .map((c) => (
                    <Row key={ element_id + '_' + c['international_quote_id'] } className='pt-3 pb-3 border-bottom align-items-center'>
                        <Col xs={2}>
                            <Button
                                variant={isCountrySelected(c) ? "primary" : "outline-primary"}
                                onClick={() => {
                                    const checkbox = document.getElementById(element_id + '_' + c['international_quote_id'] + '_checkbox');
                                    checkbox.checked = !checkbox.checked;
                                    handleOnSelectCountry(checkbox.checked, c);
                                }}
                                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                            >
                                <input
                                    id={element_id + '_' + c['international_quote_id'] + '_checkbox'}
                                    type="checkbox"
                                    checked={isCountrySelected(c)}
                                    onChange={(e) => handleOnSelectCountry(e.target.checked, c)}
                                    onClick={(e) => e.stopPropagation()}
                                    style={{ marginRight: '0.5em' }}
                                />
                                <span id={element_id + '_' + c['international_quote_id'] + '_add'}>Add</span>
                            </Button>
                        </Col>
                        <Col>{ c['country'] }</Col>
                    </Row>
                ))}
                </div>
                <Row className="mt-3 text-end">
                    <Col><Button id="save_country" onClick={ (e) => onSelectCountries(countriesTempSelect) } disabled={countriesTempSelect.length === 0}>Save</Button></Col>
                </Row>
                </>
            )}
        </>
    );
}

import Modal from 'react-bootstrap/Modal';
import Chat from '../components/Chat/Chat';

export default function ChatDialog({ element_id, show, onHide, size, username, handleAPIRequest, chatContext }) {

    if (!element_id) {
        element_id = "chat-modal";
    }

    if (!size) {
        size = "xl";
    }

    // centered

    return (
        <Modal
            id={ element_id }
            show={ show }
            onHide={ onHide }
            size={ size }
            aria-labelledby={ element_id }
        >
            <Modal.Header closeButton>
                <Modal.Title id={ element_id + '_title' }>
                    Chat
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Chat element_id={ element_id + '_search' } username={ username } handleAPIRequest={ handleAPIRequest } chatContext = { chatContext } />
            </Modal.Body>
            <Modal.Footer style={{ textAlign: 'left' }}>
                <b>Please do not share any personal, health, financial, or other sensitive information (PHI/PII/PCI) in this chat. This chat is intended for general information and support purposes only.</b>
            </Modal.Footer>
        </Modal>
    );
}

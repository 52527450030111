import { useState } from 'react';

export default function useSimulationResultsCache() {

    const getSimulationResultsCache = () => {

        console.log('loading simulation_results_cache')

        const simulation_results_cache = JSON.parse(localStorage.getItem('simulation_results_cache'));
        const sims = simulation_results_cache ? simulation_results_cache : [];
        
        return sims.filter((a) => a['version'] !== undefined && a['version'] === 10);
    };

    const [simulationResultsCache, setSimulationResultsCache] = useState(getSimulationResultsCache());

    const addSimulationResult = (sim_results) => {

        console.log('setting simulation_results_cache');

        const current = getSimulationResultsCache();

        const t = JSON.parse(JSON.stringify(current.filter((s) => 'simulation_result_id' in s && s['simulation_result_id'] !== sim_results['simulation_result_id'])));

        t.push(sim_results);

        while (t.length > 3) {
            t.shift();
        }

        localStorage.setItem('simulation_results_cache', JSON.stringify(t));
        setSimulationResultsCache(t);

    };

    return {
        addSimulationResult
    ,   simulationResultsCache
    }

}

import { useState } from 'react';
import { decodeToken, isExpired } from "react-jwt";

export default function useTokens() {

    const getNonce = () => {
        try {
            const tokenString = localStorage.getItem('nonce');
            return tokenString ? tokenString : null;
        } catch (e) {
            return null;
        }
    };

    const [nonce, setNonce] = useState(getNonce());

    const saveNonce = nonceNew => {
        if (nonceNew === null) {
            localStorage.removeItem('nonce');
            setNonce(null);
        }
        else {
            localStorage.setItem('nonce', nonceNew);
            setNonce(nonceNew);
        }
    }

    const getTokenAccess = () => {
        try {
            const tokenString = localStorage.getItem('access_token');
            return tokenString ? tokenString : null;
        } catch (e) {
            return null;
        }
    };

    const getTokenRefresh = () => {
        try {
            const tokenString = localStorage.getItem('refresh_token');
            return tokenString ? tokenString : null;
        } catch (e) {
            return null;
        }
    };

    const [tokenAccess, setTokenAccess] = useState(getTokenAccess());
    const [tokenAccessDecode, setTokenAccessDecode] = useState(tokenAccess ? decodeToken(tokenAccess) : null);
    const [tokenAccessExpired, setTokenAccessExpired] = useState(tokenAccessDecode ? isExpired(tokenAccess) : true);

    const [tokenRefresh, setTokenRefresh] = useState(getTokenRefresh());
    const [tokenRefreshDecode, setTokenRefreshDecode] = useState(tokenRefresh ? decodeToken(tokenRefresh) : null);
    const [tokenRefreshExpired, setTokenRefreshExpired] = useState(tokenRefreshDecode ? isExpired(tokenRefresh) : true);

    const saveTokenAccess = tokenNew => {
        if (tokenNew === null) {
            localStorage.removeItem('access_token');
            setTokenAccess(null);
            setTokenAccessDecode(null);
            setTokenAccessExpired(true);
        }
        else {
            localStorage.setItem('access_token', tokenNew);
            setTokenAccess(tokenNew);
            setTokenAccessDecode(tokenNew ? decodeToken(tokenNew) : null);
            setTokenAccessExpired(tokenNew ? isExpired(tokenNew) : true);
        }
    };

    const saveTokenRefresh = tokenNew => {
        if (tokenNew === null) {
            localStorage.removeItem('refresh_token');
            setTokenRefresh(null);
            setTokenRefreshDecode(null);
            setTokenRefreshExpired(true);
        }
        else {
            localStorage.setItem('refresh_token', tokenNew);
            setTokenRefresh(tokenNew);
            setTokenRefreshDecode(tokenNew ? decodeToken(tokenNew) : null);
            setTokenRefreshExpired(tokenNew ? isExpired(tokenNew) : true);
        }
    };

    return {
        setNonce: saveNonce,
        nonce,

        setTokenAccess: saveTokenAccess,
        tokenAccess,
        tokenAccessDecode,
        tokenAccessExpired,

        setTokenRefresh: saveTokenRefresh,
        tokenRefresh,
        tokenRefreshDecode,
        tokenRefreshExpired
    };

}
